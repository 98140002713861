import React from "react";
import { SEO, PageLayout, HeroElement } from "@bluefin/components";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class OrderOnlinePage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Order Online"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                {
                  internal: false,
                  text: "Call Us",
                  link: fishermanBusiness.ctas.phone.link,
                },
                {
                  text: "GrubHub",
                  link: "https://iipostorestaurant.dine.online",
                  internal: false,
                },
                {
                  text: "UberEats",
                  link: "https://www.order.store/store/il-posto-restaurant/qyxIQ6BcSLKBKJa3L6jY1Q",
                  internal: false,
                },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        />
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          link
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Order Online" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
